.footer-logos {
  max-height: 150px;
  max-width: 250px;
}

.btn:focus {
  box-shadow: none;
}

@media print {
  .no-print { display: none;}
}
.about {
  padding-top: 100px;
  padding-bottom: 20px;
}

.partner-buttons {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 500;
}

.toast {
  z-index: 1;
}
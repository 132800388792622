.request-body {
  background-color: #bec3c7;
  color: #0a141a;
}

.sort-filter {
  background-color: #e9ecee;
  color: #152834;
}

.edit-selected {
  background-color: #d3d9dd;
  color: #152834;
}

.requests-list-col {
  background-color: #bdc6cd;
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #b8d1ce;
  color: #0a141a;
}

.form-check-input, .form-check-input:checked {
  background-color: #89b2ae;
}

.react-datetime-picker__wrapper {
  background-color: #89b2ae;
}

.react-datetime-picker__inputGroup {
  color: black;
}

.react-calendar__month-view__days__day--weekend {
  color: #89b2ae;
}

.accordion-button.collapsed {
  background-color: #89b2ae;
}

.preview-cards {
  text-decoration: none;
  text-align: left;
}

.preview-cards:hover {
  border: #89b2ae;
}

.list-rows {
  background-color: #1c3445;
}

.list-buttons {
  background-color: hsla(205, 42%, 19%, 0) !important;
  border-color: hsla(205, 42%, 19%, 0) !important;
}

.list-delete:hover {
  color: white !important;
}